import React from "react";
import {
	useInstitutionFormState,
	INSTITUTION_FORM_STEPS,
} from "../_useInstitutionFormState";
import { InstitutionStepPage } from "../_InstitutionStepPage";
import { useApplicationUsePageFields } from "@components/ApplicationStepPage/common/Use/useApplicationUsePageFields";

const InstitutionUse: React.FunctionComponent = () => {
	const formData = useInstitutionFormState(
		INSTITUTION_FORM_STEPS.USE.formStep
	);
	const { body, onSubmit } = useApplicationUsePageFields(formData);

	return (
		<InstitutionStepPage
			currentStepIndex={INSTITUTION_FORM_STEPS.USE.formStep}
			onButtonClick={() =>
				onSubmit(() =>
					formData.navigateToStep(
						INSTITUTION_FORM_STEPS.SUMMARY.formStep
					)
				)
			}
			onBackClick={() =>
				formData.navigateToStep(
					INSTITUTION_FORM_STEPS.INSTITUTION.formStep
				)
			}
		>
			{body}
		</InstitutionStepPage>
	);
};

export default InstitutionUse;
